<template>
    <div class="content">
        <div class="title">
            价格明细
        </div>
        <div class="row1">
            <div class="left">
                <span style="color:#666666;margin-right:4px;">套餐总价</span>
                <span style="color:#cccccc;font-size:12px;">月套餐</span>
            </div>
            <div class="right">
                <span style="font-size:9px;">￥</span>
                <span>{{itemPrice}}</span>
            </div>
        </div>
        <div class="row2">
            <div class="left">
                <img src="@/assets/images/bigFlow/tqq.png" alt="">
                <span style="font-weight:600;">现金券抵扣</span>
            </div>
            <div class="right" @click="openList()">
                <span class="cardNum">{{selist.length}}张</span>
                <span class="noimportant">减￥</span>
                <span class="cardPrice">{{selist[0]?selist[0].value:0}}</span>
                <van-icon name="arrow" size="12" />
            </div>
        </div>
        <div class="row3">
            <div class="left">
                <span style="font-weight:600;">合计</span>
            </div>
            <div class="right">
                <span style="font-size:12px;">￥</span>
                <span>{{sub}}</span>
            </div>
        </div>
    </div>
</template>
<script>
// import coupons from "@/mixin/coupons.js";
export default {
    computed: {
        sub() {
            if (this.selist[0]) { return this.itemPrice - this.selist[0].value>0?this.itemPrice - this.selist[0].value:0 } else {
                return this.itemPrice
            }

        }

    },
    props: {
        selist: {
            type: Array,
            default: () => {
                return []
            }
        },
        itemPrice: {
            type: String,
            default: () => {
                return ''
            }
        }
    },
    data() {
        return {
            list: [],

        }
    },
    // mixins: [coupons],
    mounted() {
        // this.getCoupons()
        // this.getList()
    },
    methods: {
        openList() {
            this.$emit('openPop')

        },


    },
}
</script>
<style lang="less" scoped>
.content {
    width: 100%;
    // height: 170px;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px 12px;
    box-sizing: border-box;
    margin-bottom: 20px;

    .title {
        font-size: 14px;
        font-weight: 600;
    }

    .row1,
    .row2,
    .row3 {
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
            font-size: 13px;
        }

        .right {
            font-size: 18px;
            font-weight: 600;
        }
    }

    .row2 {
        .left {
            display: flex;
            align-items: center;

            img {
                width: 16px;
                height: 16px;
                margin-right: 6px;
            }
        }

        .right {
            width: 140px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .cardNum {
                display: inline-block;
                width: 27px;
                height: 15px;
                line-height: 15px;
                text-align: center;
                font-size: 12px;
                font-weight: 400;
                padding: 2px;
                border-radius: 4px;
                color: #fff;
                background-color: #fe4c56;
                margin-right: 6px;
            }

            .noimportant {
                font-weight: 600;
                font-size: 9px;
                color: #fe4c56;
            }

            .cardPrice {
                font-size: 18px;
                color: #fe4c56;
            }
        }
    }
}
</style>